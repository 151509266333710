import React from 'react';

const PrivacyContent = ({handleClosePrivacyModal, privacyModalIsOpen}) => {
    return (
        <div className={`privacy_content_wrapper ${privacyModalIsOpen ? 'opened' : ''}`} onClick={handleClosePrivacyModal}>
            <div className={`privacy_content ${privacyModalIsOpen ? 'opened' : ''}`} onClick={(e) => e.stopPropagation()}>
                <button onClick={handleClosePrivacyModal}>X</button>
                <h2>Политика конфиденциальности</h2>
                <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
                <p>1.1. Настоящая политика конфиденциальности составлена в соответствии с требованиями п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 г. №152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных ООО «АВТОХАУС», ОГРН 1186658046222, ИНН 6658517825 Юр. адрес: 300012, Тульская область, г. Тула, ул. Рязанская, д. 28б, помещ. 1 (далее – Оператор).</p>
                <p>1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта autohouse-haval.ru</p>
                <p>1.3. Использование Пользователем веб-сайта означает согласие Пользователя с настоящей Политикой.</p>
                <h3>2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
                <p>Персональные данные – любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных). Оператор персональных данных (оператор) – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                <p>Обработка персональных данных – любое действие (операция) или совокупность действий (операций) с персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка персональных данных включает в себя в том числе:</p>
                <p>− сбор;</p>
                <p>− запись;</p>
                <p>− систематизацию;</p>
                <p>− накопление;</p>
                <p>− хранение;</p>
                <p>− уточнение (обновление, изменение);</p>
                <p>− извлечение;</p>
                <p>− использование;</p>
                <p>− передачу (распространение, предоставление, доступ);</p>
                <p>− обезличивание;</p>
                <p>− блокирование;</p>
                <p>− удаление;</p>
                <p>− уничтожение.</p>
                <p><strong>Автоматизированная обработка персональных данных </strong>– обработка персональных данных с помощью средств вычислительной техники.</p>
                <p><strong>Распространение персональных данных</strong> – действия, направленные на раскрытие персональных данных неопределенному кругу лиц.</p>
                <p><strong>Предоставление персональных данных </strong>– действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
                <p><strong>Блокирование персональных данных </strong>– временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).</p>
                <p><strong>Уничтожение персональных данных </strong>– действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.</p>
                <p><strong>Обезличивание персональных данных </strong>– действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.</p>
                <p><strong>Информационная система персональных данных </strong>– совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку, информационных технологий и технических средств.</p>
                <p><strong>рансграничная передача персональных данных </strong>– передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.</p>
                <p><strong>Веб-сайт </strong>– совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет</p>
                <p><strong>Пользователь </strong>– любой посетитель веб-сайта</p>
                <p><strong>«Cookies» </strong>— небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего веб-сайта.</p>
                <p><strong>«IP-адрес» </strong>— уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
                <h3>3. ЦЕЛИ СБОРА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
                <p>3.1. Персональные данные обрабатываются Оператором в следующих целях:</p>
                <p>− подбор, путем предоставление сервиса для подачи заявок, и приобретение банковских продуктов и услуг Оператора и/или третьих лиц;</p>
                <p>− заключение с субъектами персональных данных любых договоров и их дальнейшего исполнения;</p>
                <p>− обратная связь с субъектами персональных данных, в том числе обработка их запросов и обращений, информирование о работе веб-сайта, об имеющихся услугах и новых услугах (путем прямых контактов с помощью средств связи), предоставляемых Оператором и/или третьими лицами, как в отдельности, так и совместно;</p>
                <p>− направление рассылок (с помощью средств связи, включая электронные средства связи, почтовые отправления, SMS-сообщения), в том числе имеющих рекламный и маркетинговый характер;</p>
                <p>− контроль и улучшение качества услуг и сервисов Оператора, в том числе предложенных на веб-сайте;</p>
                <p>− формирование статистической отчетности;</p>
                <p>− осуществление хозяйственной деятельности;</p>
                <p>− осуществление иных функций, полномочий и обязанностей, возложенных на Оператора законодательством РФ.</p>
                <p>3.2. Срок обработки персональных данных ограничивается достижением Оператором конкретных, заранее определенных и законных целей.</p>
                <p>3.3. Оператор обеспечивает соответствие содержания и объема, обрабатываемых персональных данных заявленным целям обработки и, в случае необходимости, принимает меры по устранению их избыточности по отношению к заявленным целям обработки.</p>
                <h3>4. ПРАВОВЫЕ ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
                <p>4.1. Правовыми основаниями обработки персональных данных Оператором являются:</p>
                <p>− Конституция РФ;</p>
                <p>− Гражданский кодекс РФ;</p>
                <p>− Федеральный закон от 27 июля 2006 г. N 149-ФЗ «Об информации, информационных технологиях и о защите информации»;</p>
                <p>− Указ Президента РФ от 06.03.1997 г. № 188 «Об утверждении Перечня сведений конфиденциального характера»</p>
                <p>− Постановление Правительства РФ от 15.09.2008 г. № 687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;</p>
                <p>− Постановление Правительства Российской Федерации от 1 ноября 2012 г. N 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;</p>
                <p>− Приказ Роскомнадзора от 5 сентября 2013 г. N 996 «Об утверждении требований и методов по обезличиванию персональных данных»;</p>
                <p>− Приказ ФСТЭК России от 18 февраля 2013 г. N 21 «Об утверждении состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;</p>
                <p>− уставные документы и локальные нормативные акты Оператора;</p>
                <p>− договоры, заключаемые между оператором и субъектом персональных данных;</p>
                <p>− согласия субъектов персональных данных на обработку персональных данных.</p>
                <h3>5. ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ, КАТЕГОРИИ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
                <p>5.1. Оператор может обрабатывать персональные данные следующих субъектов персональных данных:</p>
                <p>− клиенты и контрагенты Оператора (физические лица);</p>
                <p>− представители/работники клиентов и контрагентов Оператора (юридических лиц);</p>
                <p>− посетители веб-сайта Оператора.</p>
                <p>5.2. Перечень обрабатываемых персональных данных Пользователей:</p>
                <p>− Фамилия, имя, отчество;</p>
                <p>− Пол;</p>
                <p>− Электронный адрес;</p>
                <p>− Номер телефона;</p>
                <p>− Год, месяц и дата рождения;</p>
                <p>− Паспортные данные;</p>
                <p>− Адрес фактического места проживания и регистрации по месту жительства и (или) по месту пребывания</p>
                <p>− Место работы;</p>
                <p>− Сведения об образовании, профессии, специальности и квалификации, реквизиты документов об образовании;</p>
                <p>− Сведения о семейном положении и составе семьи;</p>
                <p>− Данные, автоматически передаваемые в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в т.ч. IPадрес, данные из cookies, информация о браузере, операционной системе, времени доступа, поисковых запросах, сведения о местоположении и перемещении устройства Пользователя.</p>
                <p>5.3 Оператор обрабатывает биометрические персональные данные при условии письменного согласия соответствующих субъектов персональных данных, а также в иных случаях, предусмотренных законодательством Российской Федерации.</p>
                <p>5.4. Обработка специальных категорий персональных данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, интимной жизни, Оператором не осуществляется.</p>
                <p>5.5. Трансграничная передача персональных данных Оператором не осуществляется.</p>
                <p>5.6. Допускается раскрытие третьим лицам и распространение персональных данных при наличии согласия субъекта персональных данных, если иное не предусмотрено федеральным законом.</p>
                <p>5.7. Оператор вправе поручить обработку персональных данных другому лицу с согласия субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных, предусмотренные Законом о персональных данных и настоящей Политикой.</p>
                <h3>6. ПОРЯДОК И УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
                <p>6.1. Обработка персональных данных Оператором осуществляется следующими способами:</p>
                <p>− неавтоматизированная обработка персональных данных;</p>
                <p>− автоматизированная обработка персональных данных с передачей полученной информации по информационно-телекоммуникационным сетям или без таковой;</p>
                <p>− смешанная обработка персональных данных.</p>
                <p>6.2. Перечень действий, совершаемых Оператором с персональными данными: сбор, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, распространение (в том числе передача), обезличивание, блокирование, уничтожение, а также осуществление любых иных действий в соответствии с действующим законодательством РФ.</p>
                <p>6.3. Обработка персональных данных осуществляется Оператором при условии получения согласия субъекта персональных данных (далее — Согласие), за исключением установленных законодательством РФ случаев, когда обработка персональных данных может осуществляться без такого Согласия.</p>
                <p>6.4. Субъект персональных данных принимает решение о предоставлении его персональных данных и дает Согласие свободно, своей волей и в своем интересе.</p>
                <p>6.5 Согласие дается в любой позволяющей подтвердить факт его получения форме. В предусмотренных законодательством РФ случаях Согласие оформляется в письменной форме.</p>
                <p>6.6. Оператор обрабатывает персональные данные Пользователя только в случае проставления отметки в чек-боксе, а также путем заполнения и/или отправки персональных данных Пользователем через специальные формы, расположенные на вебсайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие на обработку персональных данных и согласие с данной Политикой.</p>
                <p>6.7. Условием прекращения обработки персональных данных может являться достижение целей обработки персональных данных, истечение срока действия Согласия или отзыв Согласия субъектом персональных данных, а также выявление неправомерной обработки персональных данных.</p>
                <p>6.8. Согласие может быть отозвано путем письменного уведомления, направленного в адрес Компании заказным почтовым отправлением.</p>
                <p>6.9. Оператор при обработке персональных данных принимает или обеспечивает принятие необходимых правовых, организационных и технических мер для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных.</p>
                <p>6.10. Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, в течение срока не дольше, чем этого требуют цели обработки персональных данных, кроме случаев, когда срок хранения персональных данных установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.</p>
                <h3>7. АКТУАЛИЗАЦИЯ, ИСПРАВЛЕНИЕ, УДАЛЕНИЕ И УНИЧТОЖЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ, ОТВЕТЫ НА ЗАПРОСЫ СУБЪЕКТОВ ПЕРСОНАЛЬНЫХ ДАННЫХ НА ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</h3>
                <p>7.1. В случае подтверждения факта неточности персональных данных или неправомерности их обработки персональные данные подлежат их актуализации Оператором, или их обработка должна быть прекращена соответственно.</p>
                <p>7.2. Факт неточности персональных данных или неправомерности их обработки может быть установлен либо субъектом персональных данных, либо компетентными государственными органами РФ.</p>
                <p>7.3. По письменному запросу субъекта персональных данных или его представителя Оператор обязан сообщить информацию об осуществляемой им обработке персональных данных указанного субъекта.</p>
                <p>7.4. В порядке, предусмотренном п. 7.3, субъект персональных данных вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
                <p>7.5. При достижении целей обработки персональных данных, а также в случае отзыва субъектом персональных данных Согласия, персональные данные подлежат уничтожению, если:</p>
                <p>– Оператор не вправе осуществлять обработку без Согласия субъекта персональных данных;</p>
                <p>– иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;</p>
                <p>– иное не предусмотрено иным соглашением между Оператором и субъектом персональных данных.</p>
                <h3>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
                <p>8.1. Все отношения, касающиеся обработки персональных данных, не получившие отражения в настоящей Политике, регулируются согласно положениям законодательства РФ.</p>
                <p>8.2. В случае изменения действующего законодательства РФ, внесения изменений в нормативные документы по защите персональных данных настоящая Политика действует в части, не противоречащей действующему законодательству до приведения его в соответствие с такими.</p>
                <p>8.3. Условия настоящей Политики устанавливаются, изменяются и отменяются Оператором в одностороннем порядке без предварительного уведомления Пользователя. С момента размещения на веб-сайте новой редакции Положения предыдущая редакция считается утратившей свою силу.</p>
                <p>8.4. Если Пользователь не согласен с условиями настоящей Политики, то он должен прекратить использование веб-сайта, в противном случае продолжение использования Пользователем веб-сайта означает, что Пользователь согласен с условиями настоящей Политики.</p>
            </div>
        </div>
    );
};

export default PrivacyContent;