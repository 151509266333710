import React from 'react';
import cl from './inputText.module.css';

const InputText = (props) => {
    return (
        <div>
            <input 
                type="text" 
                name="inputText" 
                placeholder={props.placeholder} 
                className={cl.input} 
                onChange={(e) => props.onChange(e.target.value)}
                value={props.value} 
            />
        </div>
    );
};

export default InputText;