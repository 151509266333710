import React, { useContext } from 'react';
import logo from './../img/logo.png';
import Context from './scripts/Context';
import Btn from './UI/btn/Btn';

const BurgerMenu = ({openBurger, setOpenBurger}) => {
    const context = useContext(Context);

    const scrollToTarget = (target) => {
        if (target) {
            target.scrollIntoView({behavior: 'smooth'})
            setOpenBurger(false)
        }
    }
    
    return (
        <div className={`burger_menu_wrapper ${openBurger ? 'open' : ''}`}>
            <div className={`burger_menu ${openBurger ? 'open' : ''}`}>
                <div>
                    <button onClick={() => setOpenBurger(false)}>X</button>
                    <div className='header_logo_section' style={{marginTop: '20px'}}>
                        <img src={logo} alt="logo" />
                        <p className='header_logo_section_title'>Автосалон HAVAL</p>
                    </div>
                    <ul className='main_menu_list mobile'>
                        <li className='main_menu_item'>
                            <a href="#autos" onClick={() => scrollToTarget(context.toScroll.timer)}>Авто в наличии</a>
                        </li>

                        <li className='main_menu_item'>
                            <a href="#complects" onClick={() => scrollToTarget(context.toScroll.chooseCom)}>Комплектации</a>
                        </li>

                        <li className='main_menu_item'>
                            <a href="#trade_in" onClick={() => scrollToTarget(context.toScroll.offerInput)}>Трейд ин</a>
                        </li>

                        <li className='main_menu_item'>
                            <a href="#creditts" onClick={() => scrollToTarget(context.toScroll.creditSec)}>Автокредит</a>
                        </li>

                        <li className='main_menu_item'>
                            <a href="#contacts" onClick={() => scrollToTarget(context.toScroll.contacts)}>Контакты</a>
                        </li>
                    </ul>
                    <div>
                        <Btn style={{width: '90%', marginBottom: '20px'}} click={() => context.setIsOpen(true)}>Обратная связь</Btn>
                    </div>
                    
                    <p>г. Нижний Новгород, ул. Июльских Дней, д. 1г</p>
                    <p>с 8-00 до 22-00</p>
                    <a href="tel:+78314204413">+7 (831) 420-44-13</a>
                </div>
            </div>
        </div>
    );
};

export default BurgerMenu;