import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Input from './UI/input/Input';
import axios from 'axios';
import { useContext } from 'react';
import Context from './scripts/Context';

const TimerSection = ({days, hours, minutes, seconds}) => {
    const [telValue, setTelValue] = useState('');

    // const handleSubmit = async () => {
    //     const emailData = {
    //         attachment: 'Пришла заявка',
    //         html: telValue
    //     };

    //     try {
    //         const response = await axios.post('http://localhost:8000/send-email', emailData);
    //         console.log('Email sent:', response.data);
    //     } catch (error) {
    //         console.error('Email error:', error);
    //     }
    // };

    const context = useContext(Context)

    return (
        <section>
            <Container fluid className='timer_section_bg'>
                <Container className='py-4'>
                    <h2><b>ОСОБЫЕ УСЛОВИЯ!</b></h2>
                    <h3>ТОЛЬКО до 25 октября 2023 СПЕЦАЛЬНАЯ ЦЕНА  НА АВТОМОБИЛИ HAVAL <br></br>ПРЕДЛОЖЕНИЕ ОГРАНИЧЕННОЕ!</h3>
                    <br></br>
                    <p>До конца акции осталось:</p>
                    <div className="timer_wrapper">
                        <div className="timer">
                            <div className="timer_item">
                                <p className='timer_name'>дней:</p>
                                <div className='timer_value'>{days}</div>
                            </div>

                            <div className="timer_item">
                                <p className='timer_name'>часов:</p>
                                <div className='timer_value'>{hours}</div>
                            </div>

                            <div className="timer_item">
                                <p className='timer_name'>минут:</p>
                                <div className='timer_value'>{minutes}</div>
                            </div>

                            <div className="timer_item">
                                <p className='timer_name'>секунд:</p>
                                <div className='timer_value'>{seconds}</div>
                            </div>
                        </div>

                        <Input handleSubmit={context.handleSubmit} setTelValue={context.setTelValue} />
                        
                        <div className="form_comment">
                            <span>Заполняя данную форму, Вы даёте согласие на </span> 
                            <strong onClick={context.handleOpenPrivacyDataModal}>обработку персональных данных</strong>
                            <span>и принимаете </span>
                            <strong onClick={context.handleOpenPrivacyModal}>условия Политики конфиденциальности</strong>
                        </div>
                    </div>

                </Container>
            </Container>
        </section>
    );
};

export default TimerSection;