import React from 'react';

const PrivacyPolicyData = ({privacyDataModalIsOpen, handleClosePrivacyDataModal}) => {
    return (
        <div className={`privacy_content_wrapper ${privacyDataModalIsOpen ? 'opened' : ''}`} onClick={handleClosePrivacyDataModal}>
            <div className={`privacy_content ${privacyDataModalIsOpen ? 'opened' : ''}`} onClick={(e) => e.stopPropagation()}>
                <button onClick={handleClosePrivacyDataModal}>X</button>
                <h2>Согласие на обработку персональных данных</h2>
                <p>Настоящим Я, действуя своей волей и в своем интересе, при размещении (вводе) своих персональных данных на Веб-сайте autohouse-haval.ru (далее — Веб-сайт), предоставляю ООО «АВТОХАУС», ОГРН 1186658046222, ИНН 6658517825 Юр. адрес: 300012, Тульская область, г. Тула, ул. Рязанская, д. 28б, помещ. 1 (далее — Оператор), свое согласие на обработку моих персональных данных, к которым относятся:</p>
                <p>− Фамилия, имя, отчество;</p>
                <p>− Пол;</p>
                <p>− Электронный адрес;</p>
                <p>− Номер телефона;</p>
                <p>− Год, месяц и дата рождения;</p>
                <p>− Паспортные данные;</p>
                <p>− Адрес фактического места проживания и регистрации по месту жительства и (или) по месту пребывания</p>
                <p>− Место работы;</p>
                <p>− Сведения об образовании, профессии, специальности и квалификации, реквизиты документов об образовании;</p>
                <p>− Сведения о семейном положении и составе семьи.</p>
                <p>Настоящим даю свое согласие на обработку моих персональных данных в следующих целях:</p>
                <p>− подбор, путем предоставление сервиса для подачи заявок (анкет), и приобретение банковских продуктов и услуг Оператора и/или третьих лиц;</p>
                <p>− заключение с субъектами персональных данных любых договоров и их дальнейшего исполнения;</p>
                <p>− обратная связь с субъектами персональных данных, в том числе обработка их запросов и обращений, информирование о работе веб-сайта, об</p>
                <p>имеющихся услугах и новых услугах (путем прямых контактов с помощью средств связи), предоставляемых Оператором и/или третьими лицами, как в отдельности, так и совместно;</p>
                <p>− направление рассылок (с помощью средств связи, включая электронные средства связи, почтовые отправления, SMS-сообщения), в том числе имеющих рекламный и маркетинговый характер, предоставляемых Оператором и/или третьими лицами, как в отдельности, так и совместно;</p>
                <p>− контроль и улучшение качества услуг и сервисов Оператора, в том числе предложенных на веб-сайте;</p>
                <p>− формирование статистической отчетности;</p>
                <p>− осуществление хозяйственной деятельности;</p>
                <p>− осуществление иных функций, полномочий и обязанностей, возложенных на Оператора законодательством РФ.</p>
                <p>Настоящим даю свое согласие на поручение обработки моих персональных данных следующими третьими лицами:</p>
                <p>− Банк ВТБ (Публичное акционерное общество) (Банк ВТБ (ПАО)) (ОГРН 1027739609391, Российская Федерация, место нахождения: 190000, г. Санкт-Петербург, ул. Большая Морская, д. 29);</p>
                <p>− Акционерное общество «ТИНЬКОФФ БАНК» (АО «Тинькофф Банк») (ОГРН 1027739642281, Российская Федерация, место нахождения: 123060, Москва,Волоколамский 1-й проезд, 10, корп. 1);</p>
                <p>Настоящим также выражаю согласие на передачу указанных выше моих персональных данных третьим лицам (перечень третьих лиц приведен выше), непосредственно оказывающим запрошенную мной услугу по предоставлению кредитного продукта, на основании заключённых с ними договоров в целях исполнения заключённого мной с ООО «АВТОХАУС» договора и обрабатывающие персональные данные в указанных выше целях на законных основаниях.</p>
                <p>Настоящим предоставляю Оператору, а также третьим лицам мое согласие на получение сведений из основной части кредитных историй (кредитного отчета) в соответствии с Федеральным законом РФ № 218-ФЗ «О кредитных историях» и всей иной информации, предоставленной с моего согласия, хранящихся в бюро кредитных историй, с целью проверки моей благонадежности, а также рассмотрения указанными лицами возможности предоставления мне потребительского кредита или потребительского займа.</p>
                <p>Я также даю согласие на законную обработку моих персональных данных и иной информации (в том числе технических файлов cookies, данных об оказанных мне услугах, обIP-адресе, информации о браузере, операционной системе, времени доступа, поисковых запросах, сведения о местоположении и перемещении моего устройства), полученных в сети Интернет от третьих лиц, а также данных из социальных сетей: «ВКонтакте», “Instagram”, “Facebook”, “Twitter”, «Одноклассники.ru».</p>
                <p>Настоящее согласие предоставляется на осуществление любых действий в отношении моих персональных данных, которые необходимы и желаемы для достижения вышеуказанных целей, включая, без ограничения: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, распространение (передачу) (в том числе передачу лицам, указанным в настоящем согласии), обезличивание, блокирование и уничтожение персональных данных, под которыми понимаются все данные, указанные в анкете (заявке), заполненной на Сайте Оператора</p>
                <p>Настоящее согласие действует до достижения целей обработки персональных данных за исключением случаев, когда более длительный срок их обработки предусмотрен законодательством Российской Федерации. Настоящее право (согласие) может быть отозвано, если иное не установлено действующим законодательством Российской Федерации, путем направления мной письменного уведомления (запроса) в адрес Оператора, которому передаются персональные данные, по указанному выше адресу. Настоящее согласие считается отозванным по истечении 30 (тридцати) календарных дней с момента получения Оператором письменного уведомления (запроса) об отзыве настоящего согласия.</p>
            </div>
        </div>
    );
};

export default PrivacyPolicyData;