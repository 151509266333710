import React, {useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs, Navigation, FreeMode } from 'swiper/modules';
import './../../../../node_modules/swiper/swiper.css'

const Slider = ({imgs}) => {
    const [activeSlideIndex, setActiveSlideIndex] = useState(null);

    return (
        <div className='d-block d-md-flex w-100'>
            <Swiper 
                slidesPerView={8}
                freeMode={true}
                spaceBetween={5}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]} 
                onSwiper={setActiveSlideIndex}
                className='thumb_swiper'
            >
                {imgs.map((item, index) => (
                    <SwiperSlide className='thumb_slider_item'>
                        <img src={item} alt={item} key={index} />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Swiper
                slidesPerView={1}
                className='main_swiper' 
                modules={[FreeMode, Thumbs, Navigation]}
                navigation={true}
                thumbs={{swiper: activeSlideIndex && !activeSlideIndex.destroyed ? activeSlideIndex : null }}
            >
                {imgs.map((item, index)=> (
                    <SwiperSlide className='main_slider_item'>
                        <img src={item} alt={item} key={index} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Slider;