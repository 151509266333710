import React from 'react';
import { Container } from 'react-bootstrap';

import agreement from './../img/agreement.gif';
import car from './../img/car.gif';
import credit from './../img/credit.gif';
import payment from './../img/payment.gif';
import present from './../img/present.gif';

const TopReasons = () => {
    return (
        <Container fluid>
            <Container className='text-center py-4'>
                <h2 className='pb-4'>Топ-5 причин обратиться к нам</h2>

                <div className='reasons'>
                    <div>
                        <div className="reasons_gifs_wrapper">
                            <img src={agreement} alt={agreement} className='reasons_gifs' />
                        </div>
                        

                        <p>Сделка в день визита</p>
                    </div>
                    
                    <div>
                        <div className="reasons_gifs_wrapper">
                            <img src={car} alt={car} className='reasons_gifs' />
                        </div>
                        

                        <p>Trade-in до 250 000 ₽!</p>
                    </div>

                    <div>
                        <div className="reasons_gifs_wrapper">
                            <img src={credit} alt={credit} className='reasons_gifs' />
                        </div>
                        

                        <p>Льготное кредитование от 4,9%</p>
                    </div>

                    <div>
                        <div className="reasons_gifs_wrapper">
                            <img src={payment} alt={payment} className='reasons_gifs' />
                        </div>
                        

                        <p>Рассрочка 0%</p>
                    </div>

                    <div>
                        <div className="reasons_gifs_wrapper">
                            <img src={present} alt={present} className='reasons_gifs' />
                        </div>
                        

                        <p>Акции и подарки от дилера</p>
                    </div>
                </div>
            </Container>
        </Container>
    );
};

export default TopReasons;