import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import gifts1 from './../img/gifts1.png';
import gifts2 from './../img/gifts2.png';
import gifts3 from './../img/gifts3.png';
import Context from './scripts/Context';

const Gifts = () => {
    const valueFromContext = useContext(Context);
    return (
        <Container fluid className='py-5 border'>
            <Container>
                <div>
                    <h3 className='gifts_title'>Подарок на выбор</h3>
                    <div className="gifts_list">
                        <div className='gifts_item' onClick={() => valueFromContext.setIsOpen(true)}>
                            <img src={gifts1} alt={gifts1} />
                            <span>КАСКО</span>
                        </div>

                        <div className='gifts_item' onClick={() => valueFromContext.setIsOpen(true)}>
                            <img src={gifts2} alt={gifts2} />
                            <span>3 платежа в подарок</span>
                        </div>

                        <div className='gifts_item' onClick={() => valueFromContext.setIsOpen(true)}>
                            <img src={gifts3} alt={gifts3} />
                            <span>Комплект резины</span>
                        </div>
                    </div>
                </div>
            </Container>
        </Container>
    );
};

export default Gifts;