import React from 'react';
import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Context from './scripts/Context';

const Footer = () => {
    const context = useContext(Context);
    const handleClick = (e) => {
        e.preventDefault();
        context.handleOpenPrivacyModal(true)
    }

    return (
        <footer>
            <Container fluid className='py-5'>
                <Container>
                    <div className='footer_info'>
                        <p>ООО «АВТОХАУС», ОГРН 1186658046222, ИНН 6658517825 Юр. адрес: 300012, Тульская область, г. Тула, ул. Рязанская, д. 28б, помещ. 1</p>
                        <p>Стоимость подарка не зависит от стоимости купленного а/м, покупатель может выбрать любой подарок из перечисленных при покупке а/м. Обращаем Ваше внимание на то, что данный сайт носит исключительно информационный характер и ни при каких условиях не является публичной офертой, определяемой положениями статьи 437 Гражданского кодекса Российской Федерации. Для получения более подробной информации об указанных акциях, а также о стоимости автомобилей обращайтесь к менеджерам по продажам.</p>
                        <p>Наш сайт использует файлы cookies для повышения удобства пользователей. Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлов cookies и принимаете нашу</p>
                    </div>
                    <div className='footer_privacy'>
                        <a onClick={handleClick} href=''>Политику конфиденциальности.</a>
                        <a href="https://autohouse-haval.ru/generalnaya_lizensiya_1000_-08_07_2015.pdf">Партнер автокредитования: ПАО «Банк ВТБ» Лицензия ЦБ РФ № 1000 от 08.07.2015 г.</a>
                        <a href="https://autohouse-haval.ru/insurance.pdf">Партнер автострахования: АО «Тинькофф Страхование» лицензия ОС № 0191-03 от 19.05.2015 г.</a>
                    </div>
                </Container>
            </Container>
        </footer>
    );
};

export default Footer;