import React from "react";
import cl from "./btn.module.css";
import { useContext } from "react";
import Context from "../../scripts/Context";

const Btn = ({ children, white, style, click }) => {
  const context = useContext(Context);

  return (
    <>
      {white ? (
        <button className={cl.white_btn} style={style} onClick={click}>
          {children}
        </button>
      ) : (
        <button
          className={cl.btn}
          style={style}
          onClick={click}
          disabled={context.startLoading}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default Btn;
