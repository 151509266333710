import React from 'react'
import './privacy.css';
import { useContext } from 'react';
import Context from './Context';

const PrivacyPolicy = (props) => {
    const context = useContext(Context);

    return (
        <div className='privacy_policy' style={props.style}>
            <span>Заполняя данную форму, Вы даёте согласие на </span> 
            <strong onClick={context.handleOpenPrivacyDataModal}>обработку персональных данных</strong>
            <span>и принимаете </span>
            <strong onClick={context.handleOpenPrivacyModal}>условия Политики конфиденциальности</strong>
        </div>
    );
};

export default PrivacyPolicy;