import React from "react";
import { useForm, Controller } from "react-hook-form";
import cl from "./inputPhone.module.css";
import { Toaster } from "react-hot-toast";
import { useContext } from "react";
import Context from "../../scripts/Context";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useEffect } from "react";

const InputPhone = (props) => {
  const context = useContext(Context);
  const handleChangeInput = (value) => {
    // if (value.length <= 15) {
    //     context.setTelValue(value);
    // }
    context.setTelValue(value);
  };

  useEffect(() => {
    let inputs = document.querySelectorAll(`.inputPhone`);

    for (let i = 0; i < inputs.length; i++) {
      console.log(inputs[i]);
      inputs[i].classList.remove("form-control");
    }
  }, []);

  return (
    <>
      <div>
        <Toaster />
      </div>
      <PhoneInput
        id={props?.id}
        inputStyle={props.styleInput}
        inputClass={`${
          props?.className ? props.className : cl.input
        } inputPhone`}
        containerClass={props?.containerClassName}
        containerStyle={null}
        value={context.telValue}
        onChange={handleChangeInput}
        placeholder="+7 (___)-___-____"
        country={"ru"}
        onlyCountries={["ru"]}
        enableSearch={false}
        disableSearchIcon={true}
        countryCodeEditable={false}
        disableDropdown={true}
      />
    </>
  );
};

export default InputPhone;
