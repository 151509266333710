import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import jolionCW from './../img/equipment/jolion_comfort_white.png';
import jolionCS from './../img/equipment/jolion_comfort_blue.png';

import dargoCW from './../img/equipment/dargo_comfort_white.png';
import dargoER from './../img/equipment/dargo_elite_red.png';
import dargoPG from './../img/equipment/dargo_premium_gray.png';
import dargoTB from './../img/equipment/dargo_techplus_black.png';

import h9EbB from './../img/equipment/h9_elite_b_black.png';
import h9EG from './../img/equipment/h9_elite_gray.png';
import h9PD from './../img/equipment/h9_premium_darkblue.png';

import f7CW from './../img/equipment/f7_comfort_white.png';
import f7EW from './../img/equipment/f7_elite_white.png';
import f7ES from './../img/equipment/f7_elite_blue.png';
import f7PB from './../img/equipment/f7_premium_black.png';

import f7xCW from './../img/equipment/f7x_comfort_white.png';
import f7xEB from './../img/equipment/f7x_elite_black.png';
import f7xEG from './../img/equipment/f7x_elite_gray.png';
import f7xPS from './../img/equipment/f7x_premium_sky.png';
import f7xER from './../img/equipment/f7x_elite_red.png';

import Btn from './UI/btn/Btn';
import { useContext } from 'react';
import Context from './scripts/Context';

const ChooseComplectation = () => {
    const [equipment, setEquipment] = useState([
        {name: 'Jolion', carEquipment: 'Comfort', img: jolionCW, characteristics: '1.5 (143 Л.С.) 6MT 2WD'},
        {name: 'Jolion', carEquipment: 'Comfort', img: jolionCS, characteristics: '1.5 (143 Л.С.) 7-РОБОТ 2WD'},
        {name: 'Dargo', carEquipment: 'Comfort', img: dargoCW, characteristics: '2.0 (192 Л.С.) DCТ 2WD'},
        {name: 'Dargo', carEquipment: 'Elite', img: dargoER, characteristics: '2.0 (192 Л.С.) DCT 4WD'},
        {name: 'Dargo', carEquipment: 'Premium', img: dargoPG, characteristics: '2.2 (199 Л.С.) 8AT FWD'},
        {name: 'Dargo', carEquipment: 'Tech plus', img: dargoTB, characteristics: '2.0 (192 Л.С.) DCT 4WD'},
        {name: 'H9', carEquipment: 'Elite [бензин]', img: h9EbB, characteristics: '2.0 Л. 8АКП (245 Л.С) 4WD'},
        {name: 'H9', carEquipment: 'Elite', img: h9EG, characteristics: '2.0D Л. 8АКП (190 Л.С) 4WD'},
        {name: 'H9', carEquipment: 'Premium', img: h9PD, characteristics: '2.0D Л. 8АКП (190 Л.С) 4WD'},
        {name: 'F7', carEquipment: 'Comfort', img: f7CW, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 2WD'},
        {name: 'F7', carEquipment: 'Elite', img: f7EW, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 2WD'},
        {name: 'F7', carEquipment: 'Elite', img: f7ES, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7', carEquipment: 'Premium', img: f7PB, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7', carEquipment: 'Elite', img: f7PB, characteristics: '2.0 (190 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7', carEquipment: 'Premium', img: f7EW, characteristics: '2.0 (190 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7', carEquipment: 'Tech plus', img: f7PB, characteristics: '2.0 (190 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7x', carEquipment: 'Comfort', img: f7xCW, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 2WD'},
        {name: 'F7x', carEquipment: 'Elite', img: f7xEB, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 2WD'},
        {name: 'F7x', carEquipment: 'Elite', img: f7xEG, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7x', carEquipment: 'Premium', img: f7xPS, characteristics: '1.5 (150 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7x', carEquipment: 'Elite', img: f7xER, characteristics: '2.0 (190 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7x', carEquipment: 'Comfort', img: f7xEB, characteristics: '2.0 (190 Л.С.) 7-РОБОТ 4WD'},
        {name: 'F7x', carEquipment: 'Premium', img: f7xCW, characteristics: '1.0 (67 Л.С.) 4AT FWD'},
    ]);


    const [sortedModel, setSortedModel] = useState('Выберите модель');
    const [sortedData, setSortedData] = useState([...equipment]);
    const [sortedEquipment, setSortedEquipment] = useState('Выберите комплектацию');
    const [complectationOptions, setComplectationOptions] = useState([])

    const handleSortChangeModel = (e) => {
        setSortedModel(e.target.value);
        sortDataModel(e.target.value);
    }

    const sortDataModel = (e) => {
        if (e === 'Выберите модель') {
            setSortedData([...equipment])
            setSortedEquipment('Выберите комплектацию')
            setDisabledMode(true)
        } else if (e !== 'Выберите модель') {
            const result = [...equipment.filter(i => i.name === e)]
            setSortedData([...result])
            setSortedEquipment('Выберите комплектацию')

            const arrayCom = []
            result.forEach(i => {
                if (!arrayCom.includes(i.carEquipment)) {
                    arrayCom.push(i.carEquipment)
                }
            })
            setComplectationOptions([...arrayCom])
            setDisabledMode(false)
        }
    }

    const [disabledMode, setDisabledMode] = useState(true);

    const handleSortChangeEquipment = (e) => {
        setSortedEquipment(e.target.value)
        sortDataEquipment(e.target.value)
    }

    const sortDataEquipment = (e) => {
        if (e === 'Выберите комплектацию') {
            setSortedData([...equipment.filter(i => i.name === sortedModel)])
        } else {
            setSortedData([...equipment.filter(i => i.name === sortedModel && i.carEquipment === e)])
        }
    }

    const [show, setShow] = useState(false);

    const valueFromContext = useContext(Context)

    return (
        <Container fluid>
            <Container>
                <section className='choose_equipment'>
                    <h3>Подбери свою идеальную комплектацию</h3>
                    <div className='choose_first_block'>
                        <div className='choose_select_wrapper'>
                            <p>Модель</p>
                            <select name={sortedModel} onChange={handleSortChangeModel} className='choose_selects'>
                                <option value="Выберите модель">Выберите модель</option>
                                <option value="Jolion">Jolion</option>
                                 <option value="Dargo">Dargo</option>
                                 <option value="H9">H9</option>
                                 <option value="F7">F7</option>
                                 <option value="F7x">F7x</option>
                            </select>
                        </div>

                        <div className='choose_select_wrapper'>
                            <p>Комплектация</p>
                            <select name={sortedEquipment} onChange={handleSortChangeEquipment} disabled={disabledMode} className='choose_selects'>
                                <option value="Выберите комплектацию" selected>Выберите комплектацию</option>
                                {complectationOptions.map((item, index) => (
                                    <option value={item} key={index}>{item}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div>
                        <div>
                            { show ? (
                                sortedData.map((item, index) => (
                                    <div key={index} className='choose_car_equipment'>
                                        <img src={item.img} alt={item.img} className='choose_car_img' />
                                        <p className='choose_car_title'>{item.carEquipment}</p>
                                        <p>{item.characteristics}</p>
                                        <div className='choose_btns'>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Узнать цену</Btn>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Рассчитать кредит</Btn>    
                                        </div>
                                    </div>
                                )) 
                            ) : (
                                sortedData.slice(0,2).map((item, index) => (
                                    <div key={index} className='choose_car_equipment'>
                                        <img src={item.img} alt={item.img} className='choose_car_img' />
                                        <p className='choose_car_title'>{item.carEquipment}</p>
                                        <p>{item.characteristics}</p>
                                        <div className='choose_btns'>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Узнать цену</Btn>
                                            <Btn click={() => valueFromContext.setIsOpen(true)}>Рассчитать кредит</Btn>    
                                        </div>
                                    </div>
                                ))
                            )}

                            { show ? (
                                <p onClick={() => setShow(false)} className='choose_show'>Скрыть</p>
                            ) : (
                                <p onClick={() => setShow(true)} className='choose_show'>Смотреть все</p>
                            )}
                        </div>
                    </div>
                </section>
            </Container>
        </Container>
    );
};

export default ChooseComplectation;


//     const [sortedModel, setSortedModel] = useState('Выберите комплектацию');
//     const [sortedModel, setSortedModel] = useState('Выберите комплектацию');
//     const [sortedData, setSortedData] = useState([...equipment]);
//     const [sortedData, setSortedData] = useState([...equipment]);

//     const handleSortChangeModel = (e) => {
//     const handleSortChangeModel = (e) => {
//         setSortedModel(e.target.value);
//         setSortedModel(e.target.value);
//         sortDataModel(e.target.value);
//         sortDataModel(e.target.value);
//     }
//     }

//     const sortDataModel = (e) => {
//     const sortDataModel = (e) => {
//         const sortedData = equipment.filter(i => i.name.includes(e));
//         const sortedData = equipment.filter(i => i.name.includes(e));
//         setSortedData([...sortedData])
//         setSortedData([...sortedData])
//         setDisabledMode(false);
//         setDisabledMode(false);
//     }
//     }

//     const [disabledMode, setDisabledMode] = useState(true);
//     const [disabledMode, setDisabledMode] = useState(true);
//     const [sortedEquipment, setSortedEquipment] = useState('');
//     const [sortedEquipment, setSortedEquipment] = useState('');

//     const handleSortChangeEquipment = (e) => {
//     const handleSortChangeEquipment = (e) => {
//         setSortedEquipment(e.target.value)
//         setSortedEquipment(e.target.value)
//         sortDataEquipment(e.target.value)
//         sortDataEquipment(e.target.value)
//     }
//     }

//     const sortDataEquipment = (e) => {
//     const sortDataEquipment = (e) => {
//         setSortedData([...equipment.filter(i => i.carEquipment === e && i.name.split(' ')[i.name.split(' ').length - 1] === sortedModel)])
//         setSortedData([...equipment.filter(i => i.carEquipment === e && i.name.split(' ')[i.name.split(' ').length - 1] === sortedModel)])
        
        
//     }
//     }

//     const [show, setShow] = useState(false);
//     const [show, setShow] = useState(false);

//     const valueFromContext = useContext(Context)
//     const valueFromContext = useContext(Context)

//     return (
//     return (
//         <Container fluid>
//         <Container fluid>
//             <Container>
//             <Container>
//                 <section className='choose_equipment'>
//                 <section className='choose_equipment'>
//                     <h3>Подбери свою идеальную комплектацию</h3>
//                     <h3>Подбери свою идеальную комплектацию</h3>
//                     <div className='choose_first_block'>
//                     <div className='choose_first_block'>
//                         <div className='choose_select_wrapper'>
//                             <p>Модель</p>
//                             <select name={sortedModel} onChange={handleSortChangeModel} className='choose_selects'>
//                                 <option value="Выберите модель">Выберите модель</option>
//                                 <option value="Jolion">Jolion</option>
//                                 <option value="Dargo">Dargo</option>
//                                 <option value="H9">H9</option>
//                                 <option value="F7">F7</option>
//                                 <option value="F7x">F7x</option>
//                             </select>
//                         </div>

//                         <div className='choose_select_wrapper'>
//                             <p>Комплектация</p>
//                             <select name={sortedEquipment} onChange={handleSortChangeEquipment} disabled={disabledMode} className='choose_selects'>
//                                 <option value="Выберите комплектацию" selected>Выберите комплектацию</option>
//                                 <option value="Comfort">Comfort</option>
//                                 <option value="Elite">Elite</option>
//                                 <option value="Elite [бензин]">Elite [бензин]</option>
//                                 <option value="Premium">Premium</option>
//                                 <option value="Tech plus">Tech plus</option>
//                             </select>
//                         </div>
//                     </div>

//                     <div>
                        

//                         <div>
//                             { show ? (
//                                 sortedData.map((item, index) => (
//                                     <div key={index} className='choose_car_equipment'>
//                                         <img src={item.img} alt={item.img} className='choose_car_img' />
//                                         <p className='choose_car_title'>{item.carEquipment}</p>
//                                         <p>{item.characteristics}</p>
//                                         <div className='choose_btns'>
//                                             <Btn click={() => valueFromContext.setIsOpen(true)}>Узнать цену</Btn>
//                                             <Btn click={() => valueFromContext.setIsOpen(true)}>Рассчитать кредит</Btn>    
//                                         </div>
//                                     </div>
//                                 )) 
//                             ) : (
//                                 sortedData.slice(0,2).map((item, index) => (
//                                     <div key={index} className='choose_car_equipment'>
//                                         <img src={item.img} alt={item.img} className='choose_car_img' />
//                                         <p className='choose_car_title'>{item.carEquipment}</p>
//                                         <p>{item.characteristics}</p>
//                                         <div className='choose_btns'>
//                                             <Btn click={() => valueFromContext.setIsOpen(true)}>Узнать цену</Btn>
//                                             <Btn click={() => valueFromContext.setIsOpen(true)}>Рассчитать кредит</Btn>    
//                                         </div>
//                                     </div>
//                                 ))
//                             )}

//                             { show ? (
//                                 <p onClick={() => setShow(false)} className='choose_show'>Скрыть</p>
//                             ) : (
//                                 <p onClick={() => setShow(true)} className='choose_show'>Смотреть все</p>
//                             )}
//                         </div>
//                     </div>
//                 </section>
//             </Container>
//         </Container>
//     );
// };

// export default ChooseComplectation;