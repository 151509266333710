import { Map, YMaps } from '@pbe/react-yandex-maps';
import React from 'react';
import { useContext } from 'react';
import { Container } from 'react-bootstrap';
import Context from './scripts/Context';
import Btn from './UI/btn/Btn';


const Contacts = () => {
    const valueFromProvider = useContext(Context);

    return (
        <Container fluid className='py-5'>
            <Container>
                <h2 className='contacts_title'>Контакты</h2>
                <div className='d-block d-sm-flex'>
                    <div className='contacts_list'>
                        <div className='contacts_item'>
                            <p>Адрес нашего салона:</p>
                            <p>г. Нижний Новгород, ул. Июльских Дней, д. 1г</p>
                        </div>

                        <div className='contacts_item'>
                            <p>Режим работы:</p>
                            <p>с 9-00 до 22-00</p>
                        </div>

                        <div className='contacts_item'>
                            <p>Контактный телефон:</p>
                            <p><a href="#tel">+7 (831) 420-40-84</a></p>
                        </div>

                        <div className='contacts_item requisites'>
                            <p>Реквизиты:</p>
                            <p>ООО «АВТОХАУС»</p>
                            <p>ОГРН 1186658046222</p>
                            <p>ИНН 6658517825</p>
                            <p>Юр. адрес: 300012, Тульская область, г. Тула, ул. Рязанская, д. 28б, помещ. 1</p>
                        </div>

                        <h3 style={{fontSize: '24px', fontWeight: '700'}}>Остались вопросы?</h3>
                        <p>Оставьте заявку и мы свяжемся с Вами</p>

                        <Btn style={{width: '100%'}} click={() => valueFromProvider.setIsOpen(true)}>Оставить заявку</Btn>
                    </div>

                    <div className='contacts_map'>
                        <YMaps className='map'>
                            <Map defaultState={{ center: [55.75, 37.57], zoom: 9 }} className='map' />
                        </YMaps>
                    </div>
                </div>
            </Container>
        </Container>
    );
};

export default Contacts;